/* Import Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
}

/* body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
body {
  /* @apply bg-gray-100 text-gray-900; */
/* @apply bg-gray-100 text-gray-900 w-full h-screen; */
/* }  */
.recharts-legend-wrapper {
  position: absolute !important;
  /* width: 962px !important; */
  bottom: 0px !important;
  margin-bottom: 10px;
}
